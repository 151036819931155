import { useOutletContext } from "react-router-dom";
import StationChart from "../LakeConditions/StationChart";

import { parse_time_range, MONTHS, ONE_MONTH } from "../../js/util";
import { TercAPI } from "../../js/terc_api";

import MODULES from "../../static/modules.json";
import TimePlot, { HorizontalLineAnnotation, LineAnnotation, TextAnnotation, TimePlotType } from "../LakeConditions/TimePlot";

function HistoricalTemperature() {
    const [_, module_container] = useOutletContext();

    const tab_index = module_container.tab_index;
    const bottom_tab_index = module_container.bottom_tab_index;
    const [chart_start_date, chart_end_date] = 
        parse_time_range(
            Object.values(MODULES.WEATHER.TABS)[tab_index]
            .BOTTOM_TABS[bottom_tab_index]
            .time_range
        );    

    const month_formatter = (d) => {
        let month = MONTHS[d.getMonth()].substring(0, 3).toUpperCase();
        return [month, ""];
    };

    const chart_props = {
        "y_label": "Average Monthly Temperature",
        "y_ticks": 7,
        "min_y": 0,
        "plot_type": TimePlotType.Scatter,
        "x_tick_formatter": month_formatter,
        "color": ["#cb8d99","#82dbdd"],
        "annotations": [
            new HorizontalLineAnnotation(32),
            new TextAnnotation("Freezing Point", 0.01, 32 - 1, undefined, "hanging"),
            new TextAnnotation("Monthly Avg. High", 0.85, 83.5),
            new TextAnnotation("Monthly Avg. Low", 0.85, 78),
            new LineAnnotation([[0.82, 84.75,], [0.845, 84.75]], "#cb8d99", 2),
            new LineAnnotation([[0.82, 79.25,], [0.845, 79.25]], "#82dbdd", 2)
        ]
    };

    const children = (station_data) => {
        const start_date_year = chart_start_date.getUTCFullYear();
        let time = station_data.flatMap((x) => {
            return x[TercAPI.MONTHLY_MAX_TEMPERATURE_NAME].map((_, month) => {
                month = new String(month + 1).padStart(2, "0");
                return new Date(`${start_date_year}-${month}-05T00:00Z`);
            });
        });

        let monthy_max_temperature = station_data.flatMap((x) => x[TercAPI.MONTHLY_MAX_TEMPERATURE_NAME])
        let monthy_min_temperature = station_data.flatMap((x) => x[TercAPI.MONTHLY_MIN_TEMPERATURE_NAME])
        return <TimePlot
            time={[time, time]}
            y={[monthy_max_temperature, monthy_min_temperature]}
            {...chart_props}
            />
    };

    return (
        <StationChart
            marker_data_type={TercAPI.MONTHLY_MAX_TEMPERATURE_NAME}
            children={children}
            start_date={chart_start_date}
            end_date={chart_end_date}
            />
    );
}

export default HistoricalTemperature;