import { useOutletContext } from "react-router-dom";
import StationChart from "../LakeConditions/StationChart";

import { TimePlotType } from "../LakeConditions/TimePlot";
import TimePlot from "../LakeConditions/TimePlot";
import { parse_time_range, MONTHS } from "../../js/util";
import { TercAPI } from "../../js/terc_api";
import MODULES from "../../static/modules.json";

function HistoricalPrecipitation() {
    const [_, module_container] = useOutletContext();

    const tab_index = module_container.tab_index;
    const bottom_tab_index = module_container.bottom_tab_index;
    const [chart_start_date, chart_end_date] = 
        parse_time_range(
            Object.values(MODULES.WEATHER.TABS)[tab_index]
            .BOTTOM_TABS[bottom_tab_index]
            .time_range
        );    

    const month_formatter = (d) => {
        let month = MONTHS[d.getMonth()].substring(0, 3).toUpperCase();
        return [month, ""];
    };

    const chart_props = {
        "y_label": "Average Monthly Precipitation",
        "y_ticks": 7,
        "min_y": 0,
        "plot_type": TimePlotType.Scatter,
        "x_tick_formatter": month_formatter
    };

    const children = (station_data) => {
        const start_date_year = chart_start_date.getUTCFullYear();
        let time = station_data.flatMap((x) => {
            return x[TercAPI.MONTHLY_PRECIPITATION_NAME].map((_, month) => {
                month = new String(month + 1).padStart(2, "0");
                return new Date(`${start_date_year}-${month}-05T00:00Z`);
            });
        });

        let monthly_precipitation = station_data.flatMap((x) => x[TercAPI.MONTHLY_PRECIPITATION_NAME])
        return <TimePlot
            time={time}
            y={monthly_precipitation}
            {...chart_props}
            />
    };

    return (
        <StationChart
            marker_data_type={TercAPI.MONTHLY_PRECIPITATION_NAME}
            children={children}
            start_date={chart_start_date}
            end_date={chart_end_date}
            />
    );
} 

export default HistoricalPrecipitation;