import { timeFormat } from "d3";
import IconMarker from "./IconMarker";

import "./TahoeMap.css";

function SurveyQuestion({question, answers}) {
    if (!Array.isArray(answers))
        answers = [answers];

    answers = answers.map((answer, idx) => {
        return (
            <div key={`${question}-${answer}-${idx}`}>
                • { answer }
            </div>
        );
    });

    return (
        <div className="survey-response">
            <div className="survey-question"> { question } </div>
            <div className="survey-answers"> { answers } </div>
        </div>
    );
}

const format_date = timeFormat("%m/%d/%Y at %I:%M %p");


function SurveyMarker(props) {
    //////////////////////////////////
    // Expected props
    // survey_questions: an array of SurveyQuestionInfo
    // survey_creation_date: a Date object of when the survey was created
    // images: an Array of String's that 
    // ... any other props that IconMarker accepts

    const survey = props.survey_questions
        .map((info, idx) => {
            return <SurveyQuestion
                key={`survey-question-${idx}-${info.question}`}
                question={info.question}
                answers={info.answer}
                />
        });

    const images = props.images.map((image_url, idx) => {
            return (
                <img key={`image-${idx}`} 
                    src={image_url}/>
            )
        })

    return (
        <IconMarker
            active={props.active}
            position={props.position}
            class_name={"survey-map-marker"}
            >
            <div className="survey-marker-popup"> 
                <div>
                    Reported on { format_date(props.survey_creation_date) }    
                </div>

                { survey }                
            
                <div className="survey-images"> {images} </div>

            </div>
        </IconMarker>
    )
}

export default SurveyMarker