import APP_CONFIG from "../static/app_config.json";
import MODULES from "../static/modules.json";
import { createLatLng, isNumeric } from "./util";

export class SurveyQuestionInfo {
    constructor(question, answer) {
        // question: a string
        // answer: a string
        this.question = question;
        this.answer = answer;
    }
};

// this class isn't used, but it accurately describes the type of CitizenScienceAPI.reports
// i should really migrate to TypeScript ... 
export class SurveyInfo {
    constructor(location, survey_date, survey_questions, images) {
        // location: a lat lon object from createLatLng
        // survey_date: a Date object when the survey was created
        // survey_questions: an Array of SurveyQuestionInfo
        // images: an Array of image urls of attached images
        this.location = location;
        this.survey_date = survey_date;
        this.survey_questions = survey_questions;
        this.images = images;
    }
};

export class CitizenScienceAPI {
    static initialized = false;
    static reports;

    static async initialize() {
        if (CitizenScienceAPI.initialized)
            return;
        
        CitizenScienceAPI.reports = {};

        for (let {feature_layer, schema} of Object.values(MODULES.CITIZEN_SCIENCE.TABS)) {
            if (feature_layer === undefined)
                continue;
            try {
                const num_surveys = await CitizenScienceAPI.load_feature_layer(feature_layer, schema);
                console.debug(`Read ${num_surveys} documents for ${feature_layer}`)
            } catch (err) {
                console.error(`Failed to get Citizen Science data for survey '${feature_layer}':`, err);
                CitizenScienceAPI.reports[feature_layer] = null;
            }
        }

        CitizenScienceAPI.initialized = true;
    }

    static async load_feature_layer(feature_layer, schema) {
        const layerUrl = `${APP_CONFIG.ARCGIS.baseURL}/${feature_layer}/FeatureServer/0/query`;
        const params = new URLSearchParams({
            where: `1=1`,
            outFields: '*',
            f: 'json',
            orderByFields: 'date_time DESC',
            resultRecordCount: MODULES.CITIZEN_SCIENCE.MAX_OBSERVATIONS
        });

        const response = await fetch(`${layerUrl}?${params.toString()}`);
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error.message);
        }

        if (data.features === undefined || data.features === null) {
            throw new Error(`ArcGis data is missing features! Did the api change? ${data}`)
        }

        const report = await Promise.all(
            data.features
            .filter(feature => {
                const has_valid_location = feature.geometry 
                && isNumeric(feature.geometry.x)
                && isNumeric(feature.geometry.y);
                return has_valid_location;  
            })
            .map(async (feature) => {
                const attrs = feature.attributes;
                let survey_questions = schema
                    .map((s_i) => CitizenScienceAPI.parse_schema_from_feature(s_i, attrs))
                    .filter((question) => question !== undefined && question !== null);
                    
                    const object_id = attrs.objectid;
                    const images = await CitizenScienceAPI.get_object_attachments(feature_layer, object_id);
                    
                return {
                    location: createLatLng(feature.geometry.y, feature.geometry.x),
                    survey_date: new Date(attrs.date_time),
                    survey_questions: survey_questions,
                    images: images
                };
            })
        );
        
        CitizenScienceAPI.reports[feature_layer] = report;
        return data.features.length;
    }

    static parse_schema_from_feature(schema, feature_attributes) {
        const question = schema.alias;
        const answer = feature_attributes[schema.name];
        return new SurveyQuestionInfo(question, answer);
    }

    static async get_object_attachments(feature_layer, object_id) {
        const attachments_url = `${APP_CONFIG.ARCGIS.baseURL}/${feature_layer}/FeatureServer/0/${object_id}/attachments`;
        const response = await fetch(`${attachments_url}?f=json`);
        const data = await response.json();
        if (data?.attachmentInfos === null || data?.attachmentInfos === undefined) {
            return [];
        }
        return data.attachmentInfos
            .filter((info) => info?.contentType.includes("image"))
            .map((info) => `${attachments_url}/${info.id}?w=600`)
    }

    static async is_loading() {
        return CitizenScienceAPI.reports === undefined;
    }

    static async is_unavailable() {
        return CitizenScienceAPI.reports === null;
    }

    static async get_reports() {
        try {
            await CitizenScienceAPI.initialize();
        } catch (err) {
            console.error(err);
            CitizenScienceAPI.reports = null;
            throw new Error("Failed to initialize ArcGIS data fetch");
        }
        return CitizenScienceAPI.reports;
    }
}