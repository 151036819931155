import { useOutletContext } from "react-router-dom";
import StationChart from "../LakeConditions/StationChart";
import "./RiverConditions.css";

import { TercAPI } from "../../js/terc_api";
import MODULES from "../../static/modules.json";
import { parse_time_range } from "../../js/util";
import TimePlot from "../LakeConditions/TimePlot";


function RiversCreeks(props) {
    const [_, module_container] = useOutletContext();

    const tab_index = module_container.tab_index;
    const bottom_tab_index = module_container.bottom_tab_index;
    const [chart_start_date, chart_end_date] = 
        parse_time_range(
            Object.values(MODULES.RIVER_CONDITIONS.TABS)[tab_index]
            .BOTTOM_TABS[bottom_tab_index]
            .time_range
        );


    const children = (station_data) => {
        const chart_props = {
            "y_label": "DISCHARGE CUBIC FEET PER SECOND",
            "y_ticks": 7,
            "min_y": 0
        };
        let time = station_data.map(x => x[TercAPI.TIME_NAME]);
        let discharge = station_data.map(x => x[TercAPI.RIVER_DISCHARGE_NAME]).map((x) => x < 0 ? 0 : x);
        return <TimePlot
            time={time}
            y={discharge}
            {...chart_props}
            />
    };

    return (
        <StationChart
            marker_data_type={TercAPI.RIVER_DISCHARGE_NAME}
            children={children}
            start_date={chart_start_date}
            end_date={chart_end_date}
            />
    );
}

export default RiversCreeks;