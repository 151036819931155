import { useOutletContext } from "react-router-dom";
import "./LakeConditions.css"
import StationChart from "./StationChart";
import { TercAPI } from "../../js/terc_api";
import MODULES from "../../static/modules.json";
import { parse_time_range } from "../../js/util";
import { HorizontalLineAnnotation, TextAnnotation } from "./TimePlot";
import TimePlot from "./TimePlot";


function LakeLevel(props) {
    const [_, module_container] = useOutletContext();

    const tab_index = module_container.tab_index;
    const bottom_tab_index = module_container.bottom_tab_index;
    const [chart_start_date, chart_end_date] = 
        parse_time_range(
            Object.values(MODULES.LAKE_CONDITIONS.TABS)[tab_index]
            .BOTTOM_TABS[bottom_tab_index]
            .time_range
        );

    const children = (station_data) => {
        const chart_props = {
            "y_label": "LAKE LEVEL (FT)",
            "y_ticks": 7,
            "min_y": 6220,
            "max_y": 6230,
            "annotations": [
                new HorizontalLineAnnotation(6229.1), 
                new HorizontalLineAnnotation(6223),
                new TextAnnotation("Maximum Legal Limit (6229.1 ft)", 0.5, 6229.3, "middle"),
                new TextAnnotation("Natural Rim (6223 ft)", 0.01, 6223 - 0.2, undefined, "hanging"),
            ]
        };
        let time = station_data.map(x => x[TercAPI.TIME_NAME]);
        let lake_level = station_data.map(x => x[TercAPI.LAKE_LEVEL_NAME]);
        return <TimePlot
            time={time}
            y={lake_level}
            {...chart_props}
            />
    };

    return (
        <StationChart
            marker_data_type={TercAPI.LAKE_LEVEL_NAME}
            children={children}
            start_date={chart_start_date}
            end_date={chart_end_date}
            />
    );
}

export default LakeLevel;